import IAuth from './index'
import GatewayBase from '../gatewayBase'
import { UserError } from '@/core/utils/errors'

export default class AuthNats extends IAuth {

    constructor(gateway) {
        super()
        /** @type {GatewayBase} */
        this.gateway = gateway
    }

    async login(project, login, password) {
        this.gateway.setToken(null, project)
        try {
            let result = await this.gateway.serverHttp("POST",'carabi-auth/password',{ login, password })
            return result.token
        } catch (e) {
            //Костыль для экрана авториза
            if (["InvalidLoginOrPassword","AuthError"].includes(e.name)) throw new UserError(e.message)
            throw e
        }
    }

    async logout(token) {
        await this.gateway.serverHttp("POST",'auth/logout',{})
    }

}