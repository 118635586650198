import IAuth from './index'
import CarabiClass from '../appserver'

export default class AuthAppserv extends IAuth {

    constructor(carabi) {
        super()
        /** @type {CarabiClass} */
        this.carabi = carabi
    }

    async login(project, login, password) {
        //Смена проекта
        if (this.carabi.project !== project) {
            this.carabi.unregister()
            this.carabi.project = project
        }
        //console.log('auth project',project)
        await this.carabi.authorize(login, password)
        return this.carabi.token
    }

    async logout(token) {
        this.carabi.unregister()
    }

}