import { api } from '@/core/main';
import CarabiClass from '../appserver'
import IFileStorage from './index'

export default class FileStorageAppserv extends IFileStorage {

    constructor(carabi) {
        super()
        /** @type {CarabiClass} */
        this.carabi = carabi
    }    
    
    async getAttachList(documentId) { 
        return await this.carabi.select("CARD_GET_FILE_LIST",{
            "DOC_ID" : documentId //number
        },100);
    }

    async getAttachUrl(fileId) {
        return this.carabi.address+'files/get/'+this.carabi.token+'/'+fileId
    }

    async uploadAttach(parentId, file, onUploadProgress = null) {
        let formData = new FormData()
        let head = JSON.stringify({
          "token" : this.carabi.token,
          "parent_id" : parentId
        })
        //var headBlob = new Blob([head], { type: "application/json"});
        //formData.append("data", headBlob)
        formData.append("UploadData", head)
        formData.append("files[]", file)
        
        let url = this.carabi.address+'files/upload'
        let result = await this.uploadAsync(url,formData,onUploadProgress)
        console.log('Upload result',result)

        if (result.attach_id === undefined) {
          throw new Error("Upload not return attach_id");
        }
        return result.attach_id[0]
    }    

    async deleteAttach(fileId, parentId) {
        await this.carabi.query("DELETE_ATTACHMENT_BY_ID",{
            'ATTACHMENT_ID': fileId,
            'PARENT_ID':     parentId
        });
    }

    async getFoldersList(folderId=0) {
        return await api.queries.select("CRM_GET_FOLDER_LIST",{
            "PARENT_FOLDER_ID" : folderId
        },10000)    
    }

    async getFilesList(folderId=0) {
        return await api.queries.select("CRM_GET_FILES_LIST",{
            "PARENT_FOLDER_ID" : folderId,
            "REQUIRED_HTTPS" : 1
        },10000)
    }

    async getFileUrl(curFile) {
        let domain = this.carabi.address
        if (curFile.LOAD_TYPE == "EXTERNAL") {
          domain = curFile.LOAD_PARAMS+'/';
        }
        var url = domain+'files/get/'+this.carabi.token+'/'+curFile.DOCUMENT_ID+'/'
        return url
    }
    
    async uploadFile(folderId, file, onUploadProgress = null) {
        let info = await this.carabi.selectRow("CRM_GET_FOLDER_SETTING_INFO",{
	      "FOLDER_ID" : folderId, //number
          "REQUIRED_HTTPS" : 1
        });
        let uplServer = this.carabi.address
        if (info.ADDRESS_SERVER) {
          uplServer = info.ADDRESS_SERVER+'/'
        }
        console.log('upload server',uplServer)

        let formData = new FormData();
        formData.append('UploadData',JSON.stringify({
          "token" : this.carabi.token,
          "parent_id" : 0,
          "folder_id": folderId
        }));
        formData.append('files[]',file,file.name);
        
        let url = uplServer+'files/upload'
        let result = await this.uploadAsync(url,formData,onUploadProgress)
        console.log('Upload result',result)

        let attachId = null
        if (result.attach_id !== undefined) {
            attachId = result.attach_id
        }
        //[{"SymbCode":"OK","document_id":60304585,"ext":"js","filename":"thumbs.js","folder_id":103076,"message":"","parent_id":0,"size":6945,"url":"https:\/\/veneta-files.cara.bi\/upload\/uploads\/veneta\/603\/60304585.js"}]
        if (result[0].document_id !== undefined) {
            attachId = result[0].document_id
        }
        if (!attachId) {
          throw new Error("Upload not return attach_id");
        }
        return attachId
    }

    async deleteFile(fileId, parentId) {
        await this.carabi.query("DELETE_DOC_FILE",{
            "FILE_ID" : fileId //varchar
        })
    }

}