const mutations = {

  page_title (state, data) {
    state.pageTitle = data;
    state.pageSubtitle = [];
  },
  page_subtitle (state, data) {
    state.pageSubtitle = data;
  },
  page_close (state) {
    state.pageTitle = '';
    state.pageSubtitle = [];
  },


  navBarLeftAction_show (state, data) {
    state.navBarLeftAction.visibility = data
  },
  // акшин для левой кнопки в navbar
  card_left_show (state, data) {
    state.card.leftShow = data
  },

  card_right_show (state, data) {
    state.card.rightShow = data
  },

  card_right_large (state, data) {
    state.card.rightLarge = data
  },

  card_left_nav_click (state, data) {
    state.leftNavClick = data
  },


}
export default mutations

