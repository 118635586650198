import {carabi} from '@/core/main'
import AsyncAuto from '@/core/utils/async-auto.js'
import {AsyncAutoTaskError} from '@/core/utils/async-auto.js'

export default {
    namespaced: true,
    state:      {
        profile: null,
        user_info: null,
        employee_list: []
    },
    mutations:  {
        set (state,payload) {
            state.profile = payload.profile;
            state.user_info = payload.user_info;
            state.employee_list = payload.employee_list;
        },
        clear (state) {
            state.session = null;
            localStorage.removeItem('session');
        },
    },
    actions:    {
        async load (context) {
            try {
                let result = await AsyncAuto([
                    {
                        name: 'profile',
                        func: () => carabi.selectRow('CRM_GET_USER_PROFILE',{})
                    },
                    {
                        name: 'employee_list',
                        func: () => carabi.select('GET_USER_EMPLOYEE_LIST',{},100)
                    },
                    {
                        name: 'user_info',
                        func: (result) => result.profile? carabi.selectRow('CRM_GET_USER_INFO',{'LOGIN':result.profile.LOGIN}): null,
                        req: ['profile']
                    }
                ])
                context.commit('set',result);
            } catch (e) {
                if (e instanceof AsyncAutoTaskError) {
                    throw e.taskError
                }
                else throw e
            }
        },

        async setInWork(context, inWork) {
            await carabi.query('CRM_SET_USER_WORK_STATUS',{
                STATUS : inWork?"1":"0" //number                
            },1)
        }
    },
    getters:    {

    }
}