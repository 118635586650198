
import IQueries from '../queries'
import GatewayBase from '../gatewayBase'

export default class ApiDocumentImports {
    constructor(isGateway=false, queries=null, gateway=null) {
        this.isGateway = isGateway
        /** @type {IQueries} */
        this.carabi = queries
        /** @type {GatewayBase} */
        this.gateway = gateway
    }

    async listByDockind(dockindName) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-imports/list-by-dockind", {
                dockindName
            })
        } else {
            return await this.carabi.select('CRM_GET_DOC_LOADERS_DOCKIND', { DOCKIND_NAME: dockindName }, 1000)
        }
    }

    async listByDocument(documentId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-imports/list-by-document", {
                documentId
            })
        } else {
            return await this.carabi.select("CRM_GET_DOC_LOADERS", { DOCUMENT_ID: documentId }, 1000)
        }        
    }
}