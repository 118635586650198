import Vue from 'vue'
import Vuex from 'vuex'

// modules
import config from './modules/config'
import session from './modules/session'
//import sideMenu from './modules/sideMenu'
import profile from './modules/profile'
import reports from './modules/reports'
import docKinds from './modules/docKinds'
import main from './modules/main'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    config, session, profile, reports, docKinds, main //sideMenu,
  },
  strict:  process.env.NODE_ENV !== 'production'
})

export default store
