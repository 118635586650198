import {carabi} from '@/core/main'

export default {
    namespaced: true,
    state:      {
      reports: null
    },
    mutations:  {
        set (state,payload) {
          state.reports = payload.reports;
        },
        clear (state) {
          state.reports = null;
        },
    },
    actions:  {
        async load (context) {
          if (context.state.reports) return;
          let reports = await carabi.select( "GET_CARABI_REPORT_LIST_ALL", {}, 10000);
          context.commit('set',{reports});
        },
    },
    getters:  {

    }
}