<template>
  <div id="app">
    <transition name="fade" mode="out-in" appear>
      <Main v-if="isAuthorised" />
      <Login v-else />
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'App',
    components: {
        Login: () => import(/* webpackChunkName: "Login" */ './Login'),
        Main: () => import(/* webpackChunkName: "Main" */ './MainFrame'),
    },
    computed: {
        isAuthorised() {
            return this.$store.state.session.session !== null
        }
    },
    mounted () {
    }
  }
</script>