export default {
  namespaced: true,
  state:      {
    config: {}
  },
  mutations:  {
    set (state, payload) { state.config = payload },
  },
  actions:    {
    async load (context) {
      try {
        let response = await fetch('/config.json')
        let newConfig = await response.json()

        //domain override
        let host = window.location.host;
        console.log('Current host:',host);
        if ((newConfig.domains) && (newConfig.domains[host])) {
          for (let name in newConfig.domains[host]) {
            newConfig[name] = newConfig.domains[host][name];
          }
        }

        context.commit('set', newConfig)
      } catch(e) {
        throw 'Failed to load config.json';
      }
    },
  },
  getters:    {
    
  }
}