import {carabi} from '@/core/main'

export default {
    namespaced: true,
    state:      {
      docKinds: null
    },
    mutations:  {
        set (state,payload) {
          state.docKinds = payload.docKinds;
        },
        clear (state) {
          state.docKinds = null;
        },
    },
    actions:  {
        async load (context) {
          if (context.state.docKinds) return;
          let docKinds = await carabi.select('GET_DOC_TYPE_LIST',{},10000);
          context.commit('set',{docKinds});
        },
    },
    getters:  {

    }
}