export default {
  namespaced: true,
  state:      {
    session: null,
  },
  mutations:  {
    set (state,newSession) {
      state.session = newSession;
      localStorage.setItem('session', JSON.stringify(newSession))
    },
    clear (state) {
      state.session = null;
      localStorage.removeItem('session');
    },
  },
  actions:    {
    load (context) {
      try {
        let session = localStorage.getItem('session')
        if (!session) {
          context.commit('clear')
          return;
        }
        session = JSON.parse(session)
        console.log('load', session)
        context.commit('set', session)
      } catch (e) {
        throw "Load session error -> " + e;
      }
    },
  },
  getters:    {
    
  }
}