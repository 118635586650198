import IQueries from '../queries'
import GatewayBase from '../gatewayBase'

export default class ApiDocumentFilters {
    constructor(isGateway=false, queries=null, gateway=null) {
        this.isGateway = isGateway
        /** @type {IQueries} */
        this.carabi = queries
        /** @type {GatewayBase} */
        this.gateway = gateway
    }

    async listPresets(dockindId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-filters/list-presets", {
                dockindId
            })
        } else {
            return await this.carabi.select( "CRM_GET_KIND_QUERIES9", { dockind_id : dockindId }, 100)            
        }        
    }

    /**
     * 
     * @param {*} dockindId 
     * @param {*} name 
     * @param {*} xml 
     * @param {number} id - изменяемый пресет, если null - будет создан новый
     */
    async setPreset(dockindId, name, xml, id) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-filters/set-preset", {
                dockindId, name, xml, id
            })
        } else {
            return await this.carabi.query( "CRM_SET_QUERY_CONTENT", {
                dockind_id : dockindId, //number
                name, xml, id
            })            
        }
    }

    async deletePreset(id) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-filters/delete-preset", {
                id
            })
        } else {
            await this.carabi.query( "CRM_DELETE_QUERY", { id })                
        }
    }
}