import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {

  pageTitle:  '',
  pageSubtitle: [],

  navBarLeftAction: {
    visibility: false,
    icon:       'icon__nav.svg'
  },

  card: {
    leftShow:     false,
    leftNavClick: false,
    rightShow:    false
  }

}

export default {
  namespaced: false,
  state:      state,
  actions:    actions,
  mutations:  mutations,
  getters:    getters
}
