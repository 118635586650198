class delayedFunction {
    constructor() {
        this.timeouts = [];
    }

    find(callback) {
        return this.timeouts.find((t) => (
            t.callback === callback
        ));
    }

    start(callback, ms, payload) {
        //console.log('delayedFunction start',this.timeouts);
        this.cancel(callback);
        this.timeouts.push({
          callback,
          timeout: setTimeout(()=>{
              this.cancel(callback);
              callback(payload);
          }, ms)
        });
    }

    cancel(callback) {
        let idx = this.timeouts.findIndex((t) => (
            t.callback === callback
        ));
        //console.log('delayedFunction cancel',idx,this.timeouts);
        if (idx !== -1) {
            clearTimeout(this.timeouts[idx].timeout);
            this.timeouts.splice(idx,1);
        }
    }
}
export default new delayedFunction();