import IQueries from '../queries'
import GatewayBase from '../gatewayBase'

export default class ApiHistory {
    constructor(isGateway=false, queries=null, gateway=null) {
        this.isGateway = isGateway
        /** @type {IQueries} */
        this.carabi = queries
        /** @type {GatewayBase} */
        this.gateway = gateway
    }

    /** Добавить документ в историю */
    async addDocument(documentId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents-history/add-document", {
                documentId
            })
        } else {
            return this.carabi.query('CRM_SET_RECENT', { document_id: documentId })
        }
    }

    async addDoclist(doclistId) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "documents-history/add-doclist", {
                doclistId
            })
        } else {
            return this.carabi.query('CRM_SET_RECENT', { document_id: doclistId })
        }
    }
}