export default class UrlResolver {

    constructor() {
      this.resolvedUrls = []
    }

    async resolveUrl(url) {
        let item = this.resolvedUrls.find(r => r.url === url)
        if (!item) {
            item = { url, result: null }
            try {
                let resp = await fetch(url+'/')
                let json = await resp.json()
                if (!!json.storageName) {
                    item.result = json
                }
            } catch {}
            this.resolvedUrls.push(item)
        }
        return item
    }

    /** Возвращает приоритетный рабочий url в массиве */
    //TODO: Timeout
    async resolveArray(urls) {
        let promises = urls.map(url => this.resolveUrl(url))
        let results = await Promise.all(promises)
        for (let res of results) {
            if (res.result !== null) return res
        }
        return null
    }

}