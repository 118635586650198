import IFileStorage from './index'
import GatewayBase from '../gatewayBase'

export default class FileStorageMclFiles extends IFileStorage {

    constructor(gateway) {
        super()
        ///** @type {IQueries} */gateway
        //this.queries = queries
        /** @type {GatewayBase} */
        this.gateway = gateway
    }
    
    async getAttachList(documentId) {
        return await this.gateway.serverHttp("POST",'storage-master/list',{
            "parentId": documentId,
            "parentType": "document"
        })
    }


    async getFilesList(folderId=0) {
        return await this.gateway.serverHttp("POST",'storage-master/list',{
            "parentId": folderId,
            "parentType": "folder"
        })
    }    

    async getFoldersList(folderId=0) {
        return await this.gateway.serverHttp("POST",'storage-master/folder-list',{
            "parentId": folderId,
            "parentType": "folder"
        }) 
    }

    async deleteAny(fileId, parentId, parentType) {
        await this.gateway.serverHttp("POST",'storage-master/delete',{
            "parentId": parentId,
            "parentType": parentType,
            "fileId": fileId
        })
    }

    async deleteAttach(fileId, parentId) {
        await this.deleteAny(fileId, parentId, 'document')
    }

    async deleteFile(fileId, parentId) {
        await this.deleteAny(fileId, parentId, 'folder')
    }    

    async upload(parentId, parentType, file, onUploadProgress = null) {
        let formData = new FormData()
        let head = JSON.stringify({
          "workspace": this.gateway.project,
          "token": this.gateway.token,
          "parentId": parentId,
          "parentType": parentType
        })
        //var headBlob = new Blob([head], { type: "application/json"});
        //formData.append("data", headBlob)
        formData.append("UploadData", head)
        formData.append("files[]", file)
        
        let url = this.gateway.address+'files/upload'
        let result = await this.uploadAsync(url,formData,onUploadProgress)
        console.log('Upload result',result)

        if (result.fileId === undefined) {
            throw new Error("Upload not return fileId");
        }
        return result.fileId
    }

    async uploadAttach(parentId, file, onUploadProgress = null) {
        return await this.upload(parentId, 'document', file, onUploadProgress)
    }

    async uploadFile(folderId, file, onUploadProgress = null) {
        return await this.upload(folderId, 'folder', file, onUploadProgress)
    }

    async getAttachUrl(fileId) {
        return this.gateway.address+'files/download/'+this.gateway.project+'/'+this.gateway.token+'/'+fileId+'/'
    }

    async getFileUrl(curFile) { 
        return await this.getAttachUrl(curFile.DOCUMENT_ID)
    }

}