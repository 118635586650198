
export default class IFileStorage {
    constructor() {
    }

    uploadAsync(url, data, onUploadProgress=null) {
        let xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.open("POST", url, true)
        return new Promise((resolve,reject)=>{
            xhr.upload.onprogress = (event)=>{
                if (onUploadProgress) {
                    onUploadProgress(event.loaded,event.total)
                }
            }
            xhr.onload = ()=>{
                if (xhr.status === 200) {
                    resolve(xhr.response)
                } else {
                    reject(new Error("Ошибка сервера при зарузки на "+url))
                }
            }
            xhr.onerror = (e)=>{
                reject(new Error("Сетевая ошибка загрузки на сервер "+url))
            }            
            xhr.send(data)
        })        
    }

    /** Получить список файлов прикрепленных к документу  */
    async getAttachList(documentId) { throw new Error("Not implemented") }
    /** Получить ссылку для загрузки файла аттача из хранилища */
    async getAttachUrl(fileId) { throw new Error("Not implemented") }
    /** Загрузить аттач в хранилище */
    async uploadAttach(parentId, file, onUploadProgress = null) { throw new Error("Not implemented") }
    /** Удалить аттач */
    async deleteAttach(fileId, parentId) { throw new Error("Not implemented") }

    /** Получить список папок у предка  */
    async getFoldersList(folderId=0) { throw new Error("Not implemented") }
    /** Получить список файлов у предка  */
    async getFilesList(folderId=0) { throw new Error("Not implemented") }
    /** Получить список папок и файлов у предка  */
    async getFoldersFilesList(folderId=0, order=null) {
        let rawResult = await Promise.all([
            this.getFoldersList(folderId, order),
            this.getFilesList(folderId, order)
        ])
        //convert folder structure to file struct
        rawResult[0] = rawResult[0].map( f => ({
            "DOCUMENT_ID" : f.DOCFOLDER_ID,
            "DOC_DESCR" : "Папка",
            "DOC_NAME" : f.DOCFOLDER_NAME,
            "DOC_TYPE" : "dir",
            "EVENT_DATE" : f.EVENT_DATE,
            "FILE_SIZE" : "",
            "EDIT" : f.EDIT,
            "ACCESS" : f.ACCESS,
        }) )
        
        return [...rawResult[0],...rawResult[1]]        
    }

    /** Получить ссылку для загрузки файла из хранилища */
    async getFileUrl(curFile) { throw new Error("Not implemented") }
    /** Загрузить файл в хранилище */
    async uploadFile(folderId, file, onUploadProgress = null) { throw new Error("Not implemented") }
    /** Удалить файл */
    async deleteFile(fileId, folderId) { throw new Error("Not implemented") }

}