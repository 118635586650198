<template>
    <div><slot :width="width" :height="height"></slot></div>
</template>
<script>
  import ResizeObserver from 'resize-observer-polyfill';
  import delayedFunction from "@/core/utils/delayedFunction";

  export default {
    name: 'ResizeObserver',
    props: {
        delay: {
            type: Number,
            default: 0
        }
    },
    data: ()=> ({
        width: 0,
        height: 0
    }),
    mounted() {
        let rects = this.$el.getClientRects()
        if (rects.length > 0) {
            const startWh = {
                width: rects[0].width,
                height: rects[0].height
            }
            this.changed(startWh)
        }

        this.delayedChanged = (wh)=> this.changed(wh)
        this.obs = new ResizeObserver( entries => {
            for (let entry of entries) {
                if (entry.target === this.$el) {
                    const cr = entry.contentRect;
                    const wh = {
                        width: cr.width,
                        height: cr.height
                    }
                    if (this.delay > 0) {
                        delayedFunction.start(this.delayedChanged, this.delay, wh);
                    } else {
                        this.changed(wh)
                    }
                }
            }
        });
        this.obs.observe(this.$el);
    },
    beforeDestroy() {
        if (this.obs) {
            this.obs.disconnect()
            //this.obs.unobserve(this.$el);
        }        
    },
    methods: {
        changed(wh) {
            this.width = wh.width;
            this.height = wh.height;
            this.$emit("changed",wh)
        }
    }
  }
</script>