class Popups {

  async alert(message) {
    await UIkit.modal.alert(message,{ stack: true })
  }

  async confirm(message) {
    await UIkit.modal.confirm(message, {
      stack: true,
      labels: {ok: '&nbsp;&nbsp;Да&nbsp;&nbsp;', cancel: '&nbsp;&nbsp;Нет&nbsp;&nbsp;'}
    });
  }

  notify(message, status = 'primary', timeout = 2000) {
    UIkit.notification({
      message: message,
      status:  status,
      pos:     'top-center',
      timeout
    })
  }

}

export default new Popups()
