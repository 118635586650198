import IQueries from '../queries'
import GatewayBase from '../gatewayBase'

export default class ApiReports {
    constructor(isGateway=false, queries=null, gateway=null) {
        this.isGateway = isGateway
        /** @type {IQueries} */
        this.carabi = queries
        /** @type {GatewayBase} */
        this.gateway = gateway
    }

    /** Добавить документ в историю */
    async listByDoclist(docListId, dockindName) {
        if (this.isGateway) {
            return await this.gateway.serverHttp("POST", "document-reports/list-by-doclist", {
                docListId, dockindName
            })
        } else {
            return await this.carabi.select('CRM_GET_MULTI_REPORTS', {
                CARABI_DOCLIST_ID: docListId,
                DOCKIND_NAME: dockindName
            }, 1000)
        }
    }
}